// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import indexImage from '../../images/banners/launch.jpg'
import website from '../../seo/website.json'
// import organisation from '../../seo/organisation.json'
// import geoLocation from '../../seo/geo-location.json'

import withUrl from '../../methods/withUrl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const indexImageWithUrl = withUrl(indexImage)
const yearEnds = new Date().getFullYear() + 1

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * ProductSchema - Implements schema.org ProductSchema
  *
  * @example
    <ProductSchema data={data}>
  *
  * @see {@link http://schema.org/ProductSchema | Schema.org ProductSchema Docs}
  */
const ProductSchema = ({ data: { name, description, cover } = {} }) => {
  const imageUrl =
    typeof cover === 'undefined' ? indexImageWithUrl : withUrl(cover)

  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    '@id': website.nakedUrlWithIntl,
    name,
    url: website.nakedUrlWithIntl,
    description,
    offers: {
      '@type': 'Offer',
      url: website.nakedUrlWithIntl,
      priceCurrency: 'INR',
      price: '550',
      priceValidUntil: `${yearEnds}-12-31`,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
    },
    image: {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      contentUrl: imageUrl,
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ProductSchema

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover/front.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h1>The Teachings of Flowers</h1>
            <h2>The Life and Work of the Mother of the Sri Aurobindo Ashram</h2>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.img)}
              objectPosition="center top"
            />
          </div>
          <div>
            <div>
              <p>
                “All Nature dumbly calls to her alone
                <br />
                To heal with her feet the aching throb of life
                <br />
                And break the seals on the dim soul of man
                <br />
                And kindle her fire in the deep heart of things.”
                <br />– Sri Aurobindo, Savitri
              </p>
              <p>
                “It is the great universal consciousness and the great force of
                universal love which makes all things blossom in beauty.”
                <br />– The Mother
              </p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <span>M</span>other taught that every flower has its own
              expression of a quality which is also in human beings. She was
              able to experience a flower’s spiritual essence, and she found
              that people, flowers and the earth have the same spiritual
              aspirations. Mother had the understanding and the mastery over the
              processes of Nature. With these subtle powers, she understood the
              spiritual essence of a flower. She learned that flowers naturally
              transmit their essence to people, and people can be helped by
              receiving it. She found that she could transmit subtle messages to
              people by putting them into a flower. She used flowers to help the
              people who came to her for guidance and help in their spiritual
              endeavour.
            </p>
            <p className="text-indent">
              Mother organised and managed the Sri Aurobindo Ashram. Then she
              created the Ashram School for children. She helped and guided
              increasing numbers of people of all ages. Mother’s perception of a
              flower’s spiritual essence became another name for the flower. She
              was able to give significances to over 900 flowers. She also gave
              a brief comment about the meaning of each one. All of this
              information is now available in books and on the internet. She
              used flowers and their spiritual significances constantly and for
              many different reasons. Today people all over the world benefit
              from Mother’s flower teachings. Mother left her flower teachings
              for the children of the earth to follow on their way to humanity’s
              next stage of evolution.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
